import React from 'react'
import { MenuItemSso, ViewerIcon }
    from 'onaosreact'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useDispatch, useSelector } from 'react-redux';
import { setSubItem } from '../../redux/menu/WhichMenu';
import { getAPI } from '../../api/api';

export const MenuViewerAdmin = (props) => {

    const dispatch = useDispatch()
    const subItem = useSelector(state => state.menu.subItem) 
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const divStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: '20px',

    }


    const onClickItem = (item) => {
        if (item === "clients") {
            getAPI("/clients",tokenRefresh ).then(datas => {
                if (datas.ok) {
                    // dispatch(setClientsInfos(datas.datas))
                    dispatch(setSubItem({actifSubItem:item}))

                }
            })
        }
    }


    return (
        <div style={divStyle}>
            <MenuItemSso
                text="CDAnalyse"
                icon={<ViewerIcon />}
            />
            <MenuItemSso
                text="Clients"
                active={subItem === "clients"}
                icon={<AccountCircleRoundedIcon />}
                onClick = {()=>onClickItem("clients")}
            />
      
        </div>
    )
}