import React, { useState } from 'react';
import { InputSso, TitleFont20WithoutMarginBlueSso, ButtonSsoHover, UnderTextSso, ButtonClose } from 'onaosreact'
import { useNavigate } from 'react-router-dom';
import { MenuViewer } from '../../menu/MenuDashBoard';
import { internalDiv } from '../../styles/internalDiv';
import { putAPI } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { ChangepasswordSuccess } from './ChangepasswordSucces';
import { setLastPassChange } from '../../redux/userdatas/UserDatas';

const TAB = [
    {
        title: "Ancien mot de passe",
        placeholder: "Entrer votre ancien mot de passe",
        onChange: "",
        id: "oldpassword",
        password: true,
        errors: {
            is: false,
            msg: "Le mot de passe ne correspond pas"
        },
    },
    {
        title: "Nouveau mot de passe",
        placeholder: "Entrer votre nouveau mot de passe",
        onChange: "",
        id: "newpassword",
        password: true,
        errors: {
            is: false,
            msg: "Veuillez entrer votre nouveau mot de passe"
        },
    },
    {
        title: "Confirmer votre mot de passe",
        placeholder: "Confirmer votre nouveau mot de passe",
        onChange: "",
        id: "confpassword",
        password: true,
        errors: {
            is: false,
            msg: "Veuillez confirmer votre nouveau mot de passe"
        },
    },
]

export const ChangePassword = (props) => {
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const [datas, setDatas] = useState({ oldpassword: "", newpassword: "", confpassword: "" });
    const [, updateState] = useState();
    const navigate = useNavigate();
    const [isTrue, setTrue] = useState(false);
    const dispatch = useDispatch()

    const saveName = (id, value) => {
        const tmp = { ...datas }
        tmp[id] = value
        setDatas(tmp)
    }


    const divPosition = {
        display: 'flex',
        flexDirection: 'column',
        // textAlign: 'left'
        justifyContent:'center',
        alignItems:'center',
        position:'relative',
        width : '400px',
        marginLeft : 'auto',
        marginRight : 'auto',
        marginTop:'50px'

    }

  

    const checkDatas = () => {

        let isError = false;

        for (let index = 0; index < TAB.length; index++) {
            TAB[index].errors.is = datas[TAB[index].id] === "" ? true : false

            if (TAB[index].errors.is === true) {
                isError = true
            }

            if (TAB[index].id === "confpassword" || TAB[index].id === "newpassword") {
                if (datas.newpassword !== datas.confpassword) {
                    TAB[index].errors.is = true
                    isError = true
                    TAB[index].errors.msg = "Les mots de passe ne correspondent pas"
                }
            }


            isError = !isError && TAB[index].errors.is ? true : isError
        }
        if (isError) {
            updateState({})
        } else {
            // updateState({})
            sendToApi()
        }
    }

    const sendToApi = () => {
        putAPI("/changepassword",tokenRefresh , datas).then(data => {
            if (data.done){
                setTrue(true)
                dispatch(setLastPassChange({lastchange:data.msg}))
            } else {
                if (data.msg === "wrong pass"){
                    TAB[0].errors.is = true
                    updateState({})
                }
            }
            
        })
    }

    return (
        <div style={{...internalDiv.mainContainerStyle, justifyContent:'', gap : '50px'}}>
            { isTrue &&
                <ChangepasswordSuccess />
            }
            {!isTrue &&
                <div style={divPosition}>
                    <div style = {{position:'absolute',left : '380px', top : '-40px' }}>
                    <ButtonClose 
                        onClick={() => navigate("/compte")}
                    />
                    </div>

                    <TitleFont20WithoutMarginBlueSso
                        text="Changement de mot de passe"
                    />
                    {TAB.map((data, k) =>
                        <InputSso
                            key={"inputssso_" + k}
                            title={data.title}
                            password={data.password}
                            placeholder={data.placeholder !== undefined ? data.placeholder : datas[data.id]}
                            onChange={data.onChange !== undefined ? (e) => saveName(data.id, e) : ""}
                            data={data}
                            errors={data.errors}
                        />
                    )}
                    <div style={{ marginTop: "15px" }}>
                        <ButtonSsoHover
                            text="Valider"
                            onClick={() => checkDatas()}
                        />
                        <UnderTextSso
                            undertext={"Mot de passe oublié ?"}
                            onClick={() => navigate("/oubli")}
                        />
                    </div>
                </div>
            }
        </div>
    )
}