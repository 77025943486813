import { createSlice } from '@reduxjs/toolkit';

//personnal info for log user
export const addExpertSlice = createSlice({
    name: 'addExpert',
    initialState:{
        clientUsers: [],
        expertsSelected : [],
        clientUsersSearch:[],
        expertsAvailable : [],
        showAlertExpertFind : false
    },

    reducers: {
        addExpert : (state, action ) => {
            if (
                state.expertsSelected.filter(infos => infos.email === action.payload.newExpert.email).length === 0 
                    && 
                action.payload.actualExperts.filter(datas => datas.infos["e-mail"] === action.payload.newExpert.email).length === 0 
            ) {
                state.expertsSelected.push(action.payload.newExpert)
            } else {
                state.showAlertExpertFind = true
            }
            //TODO add only if not in list (check email)
            return state
        },
        turnOffAlertExpert : (state, action) => {
            state.showAlertExpertFind = false
            return state
        },
        removeOneExpert : (state, action) => {
            state.expertsSelected.splice(action.payload.index, 1)
            return state
        },
        resetExpert : (state, action) => {
            state.expertsSelected = []
            return state
        },
        setExpertsAvailable : (state, action)=>{
            state.expertsAvailable = action.payload
            return state
        },
        setSearch : (state, action) => {
            state.clientUsersSearch = action.payload
            return state
        },

  
    },
})

export const {addExpert,turnOffAlertExpert, removeOneExpert, resetExpert, setExpertsAvailable,setSearch} = addExpertSlice.actions;

