import React from 'react'
import 'onaosreact/dist/index.css';
import { TitleFont20, DashBoardTableProfil, NeuMorphisme, TitleSubText } from 'onaosreact'
import { useSelector } from 'react-redux'
import { patchAPI } from '../../api/api';
import { useDispatch } from 'react-redux';
import { setData } from '../../redux/userdatas/UserDatas';



export const InfoGeneral = (props) => {

    const dispatch = useDispatch()
    const infos = useSelector( state => state.userDatas)
    const userInfos = infos.userInfos

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const NeuStyle = {
        // height: '280px',
        width: '720px',
        display: 'flex',
        flexDirection: 'column',
    }

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column wrap',
    }


    const loadChangeTable = (data, index) => {
        if (data !== "") {
            const datas = {
                label: index,
                value: data,
            }

            patchAPI("/userinfos", tokenRefresh, datas).then(res => {
                dispatch(setData({userDatas: {...userInfos, ...res.userdatas.infos}}))
            })
    }
    }

    return (
        <div style={divStyle} >
            <TitleSubText
                title="Informations personnelles"
                subText="Toutes les informations nécessaires pour votre profil sont ici"
            />
            <NeuMorphisme style={NeuStyle}>
                <TitleFont20
                    text={"Informations générales"}
                />
                <DashBoardTableProfil
                    // add_photo = {true}
                    datas={
                        [
             
                            {
                                label: "NOM",
                                value: userInfos.name,
                                api: 'name',
                            },
                            {
                                label: "PRENOM",
                                value: userInfos.firstname,
                                api: 'firstname',
                            },
                            {
                                label: "EMAIL",
                                value: userInfos["e-mail"],
                                api: 'e-mail',
                            },
                            {
                                label: "DATE DE NAISSANCE",
                                value: userInfos.birthday,
                                api: 'birthday',
                            },
                            {
                                label: "TELEPHONE",
                                value: userInfos.phone,
                                api: 'phone',
                            },
                            // {
                            //     label: "POSTE",
                            //     value: "Stagiaire",
                            //     api: 'poste',
                            // },
                        ]
                    }
                    returnValue={loadChangeTable}
                    edit={true}

                />
            </NeuMorphisme>
        </div>
    )
}
