import { NeuMorphisme } from 'onaosreact'
import React from 'react'
import { useSelector } from 'react-redux'
import { Products } from '../Products/Products'
import { AnnotatorInfos } from './Infos/AnnotatorInfos'
import { CDAnlyseInfos } from './Infos/CDAnlyseInfos'
import { MapperInfos } from './Infos/MapperInfos'
import { VeilleInfos } from './Infos/VeilleInfos'





export const ViewerInfos = (props) =>{

    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft : '15px',
        paddingRight : '15px',
        width : '960px',
    }

    const page = useSelector(state => state.menu.actifPage)



    return(
        <div style = {{  display : 'flex', flexDirection : 'column', alignItems:'center', gap : '25px'}}>
            <Products actif = {page} />
            <NeuMorphisme style = {NeuStyle}>
                {page === "loinc-mapper" && <MapperInfos />}
                {page === "veilleetmaj" && <VeilleInfos />}
                {page === "viewer" && <CDAnlyseInfos />}
                {page === "annotateur" && <AnnotatorInfos />}
            </NeuMorphisme>
        </div>
    )


}