import React from "react"



export const AnnotatorInfos = (props) => {

    return(
        <React.Fragment>
            <p>Système de détection automatique d'entités biomédicales nommées dans un texte médical. Dans sa dernière version l'annotateur détecte les concepts médicaux suivants : pathologie, symptôme, nom de substance, zone anatomique, examen paraclinique, dose ou posologie, valeur numérique de résultat biologique et leurs unités</p>
            <p>Actuellement en phase de développement, si vous êtes intéressé n'hésitez pas à contacter notre <a href="mailto:service-client@onaos.com?subject=Demande d'infos CDAnalyse">service client</a></p>
        </React.Fragment>
    )

}