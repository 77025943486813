import { createSlice } from "@reduxjs/toolkit";



export const modalsSlice = createSlice({
    name : "onaosProject",
    initialState:{
        openManage : false, 
        openAdd : false
    },
    reducers: {
        closeManage : (state,action) => {
            state.openManage = false
            return state
        },
        openManage : (state,action) => {
            state.openManage = true
            return state
        },
        closeAddExpert : (state,action) => {
            state.openAdd = false
            return state
        },
        openAddExpert : (state,action) => {
            state.openAdd = true
            return state
        },
    }
})


export const {closeManage, openManage, closeAddExpert, openAddExpert } = modalsSlice.actions;