import React, { useEffect } from 'react';

import { ModalWindow } from 'onaosreact'
import { AddExperts } from './AddExperts';
import { AddExpertsSelect } from './AddExpertsSelect';
import { AddExpertsFromOnaos } from './AddExpertsFromOnaos';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { turnOffAlertExpert } from '../../../../../redux/addexpert/AddExpert';


export const AddExpertsMain = (props) => {

    const showAlertExpertFind = useSelector(state => state.addExpert.showAlertExpertFind)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const modalStyle = {
        width: "auto", 
        maxHeight : "680px", 
        padding : '16px',
        display : 'flex', 
        flexDirection : 'column', 
        gap : "24px",
        position : "relative"
    }

    useEffect(()=> {
        if (showAlertExpertFind) {
            enqueueSnackbar("Utilisateur déjà présent", { 
                variant: 'error',
                onClose : ()=>dispatch(turnOffAlertExpert())
            })  
        }
    }, [showAlertExpertFind])

    return (
        <ModalWindow style={modalStyle}>                    
            <AddExperts />
            <AddExpertsFromOnaos />
            <AddExpertsSelect />
        </ModalWindow>
    )
}