import { createSlice } from "@reduxjs/toolkit";



export const menuSlice = createSlice({
    name: "menu",
    initialState: {
        menu : {
            products: {link : "/produits" },
            account : {link : "/compte"},
            viewer :{link : "/viewer"},
            update :{link : "/veilleetmaj"},
            annotator :{link : "/annotateur"},
            mapper :{link : "/loinc-mapper"},

        },
        actifPage: "products",
        subItem:"",
    },
    reducers: {
        setPage: (state, action) => {
            state.actifPage = action.payload.actifPage
            return state
        },
        setSubItem: (state, action) => {
            state.subItem = action.payload.actifSubItem
            return state
        }
    }
})


export const { setPage, setSubItem } = menuSlice.actions;