import React from "react";
import { MenuItemSso }
    from 'onaosreact'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";




export const MenuDashboard = (props) => {

    const infos = useSelector( state => state.menu)
    const actifPage = infos.actifPage
    const navigate = useNavigate()


    const divStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
        alignItems: 'start',
      
    }

    const goToPage = (label) => {
        navigate(infos.menu[label].link);    
    }


    return (
        <div style={divStyle}>
            <MenuItemSso
                text="Produits"
                active={actifPage === "products"}
                icon={<WidgetsRoundedIcon />}
                onClick={() =>goToPage("products")}
            />
            <MenuItemSso
                text="Mon compte"
                active={actifPage === "account"}
                icon={<ManageAccountsRoundedIcon />}
                onClick={() =>goToPage("account")}
            />
        </div>
    )
}