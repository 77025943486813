import React from 'react'
import { MenuItemSso, ViewerIcon }
    from 'onaosreact'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ApiRoundedIcon from '@mui/icons-material/ApiRounded';
import { useDispatch, useSelector } from 'react-redux';
import { setSubItem } from '../../redux/menu/WhichMenu';

export const MenuViewer = (props) => {

    const dispatch = useDispatch()
    const subItem = useSelector(state => state.menu.subItem) 

    const divStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: '20px',

    }

    return (
        <div style={divStyle}>
            <MenuItemSso
                text="CDAnalyse"
                icon={<ViewerIcon />}
            />
            <MenuItemSso
                text="Experts"
                active={subItem === "expert"}
                icon={<AccountCircleRoundedIcon />}
                onClick = {()=>dispatch(setSubItem({actifSubItem:"expert"}))}
            />
            <MenuItemSso
                text="Contrats"
                active={subItem === "contrats"}
                icon={<DescriptionRoundedIcon />}
                onClick = {()=>dispatch(setSubItem({actifSubItem : "contrats"}))}
            />
        </div>
    )
}