import { useSnackbar } from 'notistack'
import { ModalWindowManageExperts } from 'onaosreact'
import React from 'react'
import { useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { getAPI, patchAPI, putAPI } from '../../../../api/api'
import { closeManage } from '../../../../redux/modals/modals'
import { setExpertsProductInfos, setProductInfo } from '../../../../redux/products/products'




export const ViewerManageExpert = (props) => {

    const accountInfos = useSelector(state => state.userDatas.accountCreationInfos)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const institution = useSelector(state => state.log.institution.uuid)
    const page = useSelector(state => state.menu.actifPage)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const dispatch = useDispatch()

    const Textes = [
        {
            label: "Date d'envoie du lien : ",
            value: accountInfos.token.date.split(" ")[0],
        },
        {
            label: "Lien valide pendant : ",
            value: '2 jours',
        },
        {
            label: "Statut du compte : ",
            value: "En attente d'activation",
        },
    ]


    const updateUserInfos = (userDatas) => {
        const datas = {
            ...userDatas,
            client : institution,
            useruuid : accountInfos.infos.uuid,
            product : page
        }
        patchAPI("/updateexpert", tokenRefresh, datas).then(data => {
            if (data.ok && data.code === 1000) {
                enqueueSnackbar("Mise à jour effectuée", { variant: 'success' })
                batch(()=> {
                    dispatch(setExpertsProductInfos({
                        product :page, 
                        infos : data.infosExperts}))
                })
                if (userDatas.email !== accountInfos.infos["e-mail"]) {
                    putAPI("/resendactivation", tokenRefresh, {uuid : accountInfos.infos.uuid})
                }
            } else {
                enqueueSnackbar("Une erreur s'est produite, merci de contacter le service client (code:"+ data.code+" - "+data.msg+")", { variant: 'error',autoHideDuration: 3000 })
            }
            dispatch(closeManage())

        })

    }

    const sendLink = () => {
        const data = {
            uuid : accountInfos.infos.uuid
        }
        putAPI("/resendactivation", tokenRefresh, data).then(data => {
            if (data.ok && data.code === 1000) {
                enqueueSnackbar("Lien d'activation envoyé", { variant: 'success' })
            } else {
                enqueueSnackbar("Une erreur s'est produite, merci de contacter le service client (code:"+ data.code+" - "+data.msg+")", { variant: 'error',autoHideDuration: 3000 })
            }
        })
        dispatch(closeManage())
    }

    const onDelete = (uuid) => {
        const datas = {
            expert : uuid,
            client : institution,
            product : page,
            forceDeco : false
        }
        putAPI("/deleteexpert", tokenRefresh, datas).then(data => {
            if (data.ok && data.code === 1000) {
                batch(()=> {
                    dispatch(setProductInfo({
                        product : page, 
                        infos : data.infosProducts
                    }))
                    dispatch(setExpertsProductInfos({
                        product : page, 
                        infos : data.infosExperts}))
                })
                enqueueSnackbar("Utilisateur supprimé avec succès", { variant: 'success' })
            } else {
                enqueueSnackbar("Une erreur s'est produite, merci de contacter le service client (code:"+ data.code+" - "+data.msg+")", { variant: 'error',autoHideDuration: 3000 })
            }
        })
        dispatch(closeManage())
    }

    return (
        <ModalWindowManageExperts
            // linkSend={sendLink}
            textsDatas={Textes}
            linkValue={accountInfos.link}
            save={updateUserInfos}
            onSend = {sendLink}
            iconClick = {()=>dispatch(closeManage())}
            account = {{
                firstname : accountInfos.infos.firstname,
                name : accountInfos.infos.name,
                email : accountInfos.infos["e-mail"],
            }}
            deleteExpert = {()=>onDelete(accountInfos.infos.uuid)}
            onClickCopy = {()=>enqueueSnackbar("Lien copié dans le presse-papier", { variant: 'info' })}
        />
    )    


}