import React from 'react';

import {   TitleDashboard, NeuMorphismeFlex, DivsLine14 } from 'onaosreact'
import { AddExpertsBtns } from './AddExpertsBtns';
import { useDispatch, useSelector } from 'react-redux';
import { removeOneExpert } from '../../../../../redux/addexpert/AddExpert';



export const AddExpertsSelect = (props) => {

    const experts = useSelector(state => state.addExpert.expertsSelected)
    const accountsInfos = useSelector(state => state.products.experts.viewer)

    const dispatch = useDispatch()


    const deleteExpert = (index) => {
        dispatch(removeOneExpert({index : index}))
    }

    return (
      
        <div style = {{display : 'flex', flexDirection : 'column',gap : '13px', maxHeight : "230px", overflow : "auto" }}>
            {(experts.length > 0 || accountsInfos.length>0) && 
            <React.Fragment>
                <TitleDashboard 
                    text = "Expert sélectionés"
                />
                <NeuMorphismeFlex>
                    <div style = {{
                        display : "flex",
                        flexDirection:'column',
                    }}>
                        {experts.length>0 && experts.map((data, index) => (
                            <DivsLine14 
                                cells = {[data.nom, data.prenom, data.email]}
                                onDelete = {()=>deleteExpert(index)}
                                key = {"expert_"+index}
                            />
                        ))}

                        {accountsInfos.length>0 && accountsInfos.map((data, index) => (
                            <DivsLine14 
                                cells = {[data.infos.name, data.infos.firstname, data.infos["e-mail"]]}
                                key = {"expertin_"+index}
                                style = {{"backgroundColor": "#d9d9d9"}}
                            />
                        ))
                        }
                  
                    </div>
                </NeuMorphismeFlex>
                <AddExpertsBtns />
            </React.Fragment>}
        </div>
       
            


       
    )
}