import React from 'react'
import { useSelector } from 'react-redux'
import { MenuViewer } from './MenuViewer'
import { MenuViewerAdmin } from './MenuViewerAdmin'

export const MenuSwitch = (props) => {

    const infos = useSelector( state => state.menu)
    const actifPage = infos.actifPage
    // const access = useSelector(state => state.log.access)
    const products = useSelector(state => state.products.infos)


    const showMenu = () => {
        // if (actifPage ===  "viewer") {
            if (products?.[actifPage]?.access.DSI) return (<MenuViewer />)
            if (products?.[actifPage]?.access.AdminOnaos) return (<MenuViewerAdmin />)
        // }
  
        return (<div></div>)
    }


    return (
        <React.Fragment>
            {showMenu()}
        </React.Fragment>
    
    )
}