import { useSnackbar } from 'notistack'
import { DivLineAPI, DivsLine1114, LineTextWithLink, NeuMorphisme, Title } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putAPI } from '../../../../api/api'
import { updateAPIKey } from '../../../../redux/products/products'




export const ViewerDSIAPI = (props) => {

    const apiInfos = useSelector(state => state.products.infos.viewer.api)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const institution = useSelector(state => state.log.institution.uuid)
    const page = useSelector(state => state.menu.actifPage)

    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        width : '960px',
    }
    const titleStyle = {
        fontSize : "22px", 
        color : "#3C4043", 
        paddingTop : "6px", 
        paddingLeft : '15px'
    }

    const makeNewAPI = () => {
        const datas = {
            client : institution,
            product : page
        }
        putAPI("/refreshapikey", tokenRefresh, datas).then( data => {
            if (data.ok) {
                dispatch(updateAPIKey({
                    product : page,
                    key : data.newkey
                }))
            }
        })
    }

    return(
        <React.Fragment>
        {apiInfos.valeur !== "" &&
            <NeuMorphisme style = {NeuStyle}>
                <Title text = "Informations API" style = {titleStyle}/>  
                    <DivLineAPI 
                        label = "CLÉ API**"
                        value = {apiInfos.valeur}
                        style = {{marginLeft : '15px', marginRight : '31px'}}
                        onClickGen = {makeNewAPI}
                        onClickCopy = {()=>enqueueSnackbar("Lien copié dans le presse-papier", { variant: 'info' })}
                    />
                <DivsLine1114 
                    cells = {[
                        "DURÉE DE VALIDITE", apiInfos.validité.durée
                    ]} 
                />
                <DivsLine1114 
                    cells = {[
                        "VALIDE JUSQU'AU", apiInfos.validité.fin
                    ]} 
                />
                <LineTextWithLink 
                    text = "**Cette clé est à renseigner dans votre SGL pour lui permettre de communiquer avec notre API. Si besoin vous pouvez la régénérer. En cas de problème, merci de contacter notre "
                    link = "service client"
                    onClick = {() => console.log("click")}
                    style = {{width : '80%', marginLeft : 'auto', marginRight : 'auto', textAlign : 'center'}}
                />
            </NeuMorphisme>
        }
        </React.Fragment>
    )



}