import { createSlice } from '@reduxjs/toolkit';

//personnal info for log user
export const userDatasSlice = createSlice({
    name: 'userDatas',
    initialState:{
        userInfos: {},
        accountCreationInfos : {}
    },

    reducers: {
        setData: (state, action) => {
            state.userInfos = action.payload.userDatas
            return state
        },
        setLastPassChange: (state, action) => {
            state.userInfos.lastchange = action.payload.lastchange
            return state
        },
        setAccountCreationInfos: (state, action) => {
            state.accountCreationInfos = action.payload.infos
            return state
        },
    },
})

export const {setData, setLastPassChange, setAccountCreationInfos} = userDatasSlice.actions;

