import React from 'react'
import { NeuMorphisme, Title, DivsLine1114, LineTextWithLink, DivLineAPI, } from 'onaosreact'
import { useSelector } from 'react-redux'
import { ViewerDSIAPI } from './ViewerDSIAPI'






export const ViewerDSIContract = (props) => {

    const contratInfos = useSelector(state => state.products.infos.viewer)
    const licenceInfos = useSelector(state => state.products.infos.viewer.licences)
   
    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        width : '960px',
    }

    const titleStyle = {
        fontSize : "22px", 
        color : "#3C4043", 
        paddingTop : "6px", 
        paddingLeft : '15px'
    }


    return(

        <NeuMorphisme style = {NeuStyle}>
            <Title text = "Informations contractuelles" style = {titleStyle}/>
            <div style = {{
                display : "flex",
                flexDirection:'column',
            }}>
                <DivsLine1114 
                    cells = {[
                        "SIGNATURE DU CONTRAT", contratInfos.début
                    ]} 
                />
                <DivsLine1114 
                    cells = {[
                        "DATE D'OUVERTURE DES ACCÈS", contratInfos.début
                    ]} 
                />
                <DivsLine1114 
                    cells = {[
                        "VALIDE JUSQU'AU*", contratInfos.fin
                    ]} 
                />
                <DivsLine1114 
                    cells = {[
                        "NOMBRE DE LICENCE*", licenceInfos.nombre
                    ]} 
                />
                <LineTextWithLink 
                    text = "*Pour toutes demandes de renouvellement de contrat ou de licence, merci de contacter notre "
                    link = "service client"
                    onClick = {() => console.log("click")}
                />
            </div>
        </NeuMorphisme>
    )

}