import React from 'react'
import { NeuMorphisme, Title, DivsLine11, ButtonAjouter, InputSearch } from 'onaosreact'







export const AdminClients = (props) => {

    const clientsInfos = [
        {structure : "NOM DE LA STRUCTURE", type : "TYPE DE LA STRUCTURE", dsi : 'ADMINISTRATEUR', date : "DATE D'OUVERTURE", uuid : ''},
        {structure : "CH Privas", type : "Public", dsi : 'yoann.abel@onaos.com', date : '22/11/2022', uuid : 123},
    ]


    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        width : '960px',
    }

    

    return(
        <NeuMorphisme style = {NeuStyle}>
            <div style = {{display : 'flex', justifyContent : 'space-between', alignItems:'center', marginRight : '15px'}}>
                <Title text = "Clients" style = {{fontSize : "22px", color : "#3C4043", paddingTop : "6px", paddingLeft : '15px'}}/>
                <InputSearch 
                    style ={{height : '32px'}}
                    autoOptions = {[]}
                    iconFilter = {true}
                    placeHolder = "Rechercher un client"
                />

                <ButtonAjouter onClick = {()=> console.log("add")} />
            </div>
            <div style = {{
                display : "flex",
                flexDirection:'column',
            }}>
            
            {clientsInfos && clientsInfos.map((v, k) => (
                <DivsLine11 
                    cells = {[v.structure, v.type, v.dsi, v.date]}
                    onClick = {k === 0 ? null : ()=>console.log(k)}
                    key = {"clientInfos_"+k}
                    cellStyle = {{width : '20%'}}
                    header = {k === 0 ? true : false}
                />
            ))}
            </div>
        </NeuMorphisme>
    )

}