import { createSlice } from "@reduxjs/toolkit";



export const logSlice = createSlice({
    name: "log",
    initialState: {
        connect: false,
        tokenRefresh: "",
        dashboard: false,
        oanosProject: [],
        email: "",
        name:"",
        id: "",
        institution : {},
    },
    reducers: {
        unLog: (state, action) => {
            state.connect = false
            state.tokenRefresh = ""
            state.dashboard = false
            localStorage.clear()

            return state
        },
        setToken: (state, action) => {
            if (action.payload.token !== undefined && action.payload.tokenRefresh !== undefined) {
                state.connect = true
                localStorage.setItem('token',action.payload.token)
                state.tokenRefresh = action.payload.tokenRefresh
                state.dashboard = true
                state.oanosProject =  action.payload.onaos_projects
                state.user = action.payload.userdatas.infos.fullname
                state.email = action.payload.userdatas.infos["e-mail"]
                state.institution = action.payload.userdatas.etablissements[0]
            }

            return state
        },
        setDash: (state, action) => {
            state.dashboard = action.payload
            return state
        },
    }
})


export const { unLog, setToken, setDash, } = logSlice.actions;