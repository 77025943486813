import React from 'react';

import {  TitleAndClose, InputSsoExperts, IconButton } from 'onaosreact'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddExpert } from '../../../../../redux/modals/modals';
import { useState } from 'react';
import { addExpert, resetExpert } from '../../../../../redux/addexpert/AddExpert';
import { useSnackbar } from 'notistack';



export const AddExperts = (props) => {

    const dispatch = useDispatch()
    const licenceInfos = useSelector(state => state.products.infos.viewer.licences)
    const experts = useSelector(state => state.addExpert.expertsSelected)

    const licenceRestante = licenceInfos.nombre  - (licenceInfos.utilisées + experts.length)

    const accountsInfos = useSelector(state => state.products.experts.viewer)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const DATASTAB = {

        nom : {
             placeHolder: "Nom",
             errors: {
                 is: false,
                 msg: "",
                 metadatas : [{type : "", msg : "Veuillez entrer un nom"}]
             },
             value :""
        },
        prenom : {
            placeHolder: "Prénom",
            errors: {
                is: false,
                msg: "",
                metadatas : [{type : "", msg : "Veuillez entrer un prénom"}]
            },
            value :""
       },
        email : {
             placeHolder: "Email",
             errors: {
                 is: false,
                 msg: "",
                 metadatas : [
                     {type : "", msg : "Veuillez entrer un email"},
                     {type : "confirmationEmail", msg : "Les emails ne correspondent pas"}
                 ]
             },
             value :""
        },
        confirmationEmail : {
             placeHolder: "Confirmation email",
             errors: {
                 is: false,
                 msg: "",
                 metadatas : [
                     {type : "", msg : "Veuillez entrer un email"},
                     {type : "email", msg : "Les emails ne correspondent pas"}
                 ]
             },
             value :""
        },
     }

     const [datasTable, setTable] = useState(JSON.parse(JSON.stringify(DATASTAB)))

 


    const divInputStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems : "start",
        margin: "0px",
        width: "815px",
    }

    const saveName = (id, value) => {
        const tmp = {...datasTable}
        tmp[id]["value"] = value
        setTable(tmp)
    }

    const CheckDatas = () => {
        if (licenceRestante === 0) {
            // dispatch(setAlertState(true))
            enqueueSnackbar("Licence restante insuffisante !", { variant: 'warning' })
            return
        } 
        const tmp = {...datasTable}
        let errorState = false
        Object.values(tmp).forEach(data => {
            data.errors.metadatas.forEach(error => {
                if (error.type === ""){
                    if (data.value === "") {
                        data.errors.is = true 
                        data.errors.msg = error.msg 
                        errorState = true
                    } else {
                        data.errors.is = false
                    }
                } 
                if (error.type !== "") {
                    if (data.value !== tmp[error.type].value) {
                        data.errors.is = true
                        data.errors.msg = error.msg 
                        errorState = true
                    } 
                }
            })
        })

       setTable(tmp)
       if (!errorState) {
            const datasReturn  = Object.entries(datasTable).reduce((accu, courant) => {
                return {
                    ...accu,
                    [courant[0]] : courant[1].value 
                }
            }, [])  
            const datas = {newExpert:datasReturn, actualExperts: accountsInfos}
            dispatch(addExpert(datas))
            setTable(JSON.parse(JSON.stringify(DATASTAB)))
       } else {
        enqueueSnackbar("Veuillez renseigner l'ensemble des champs", { 
            variant: 'error',
        })  
       }


  }

    const licenceStyle = () => {
        if (licenceRestante === 0) return {color : "rgb(196, 60, 60)"}
        if (licenceRestante === 1) return {color : "rgb(250, 157, 55)"}
        return {color : "rgb(84, 166, 123)"}
    }
  

    return (
        <div style = {{display : 'flex', flexDirection : 'column',gap : '13px', }}>
            <TitleAndClose 
                title = "Ajouter un expert dans l'équipe"
                onClose = {()=>{dispatch(resetExpert()); dispatch(closeAddExpert())}}
            >
                <span style = {{fontSize:"12px", marginRight:"75px"}}>
                    Nombre de licence restante : <span style={licenceStyle()}>{licenceRestante}</span> / {licenceInfos.nombre}
                </span>
            </TitleAndClose>

            <div style={divInputStyle} >
                {Object.entries(datasTable).map((data, k) => (
                    <InputSsoExperts
                        key={"inputssso_" + k}
                        placeHolder = {data[1].placeHolder}
                        onChange={ (e) => saveName(data[0], e) }
                        value = {data[1].value}
                        errors={data[1].errors}
                    />
                )
                )}
                <IconButton >
                    <ControlPointIcon
                        style={{ color: '#1F5B78', display: 'flex' }}
                        onClick = {CheckDatas}
                    />
                </IconButton>
            </div>
        </div>
    )
}