import { createSlice } from "@reduxjs/toolkit";



export const productsSlice = createSlice({
    name: "products",
    initialState: {
        infos:{},
        experts:{},
        dsi :{},
    },
    reducers: {
        setProductInfo : (state, action) => {
            const product = action.payload.product
            state.infos[product] = action.payload.infos
            return state
        },
        setExpertsProductInfos : (state, action)=> {
            const product = action.payload.product
            state.experts[product] = action.payload.infos
            return state
        },
        setDSIProductInfos : (state, action) => {
            const product = action.payload.product
            state.dsi[product] = action.payload.infos
            return state
        },
        updateAPIKey : (state, action) => {
            const product = action.payload.product
            state.infos[product].api.valeur = action.payload.key
            return state
        }
    }
})


export const {setProductInfo, setExpertsProductInfos, setDSIProductInfos, updateAPIKey } = productsSlice.actions;