import React, { useEffect } from 'react';
import { TitleFont20WithoutMarginBlueSso, UnderTextSso, IconOnaos, useTimerRedirections } from 'onaosreact'
import { internalDiv } from '../../styles/internalDiv';
import { useNavigate } from 'react-router-dom';
import { Ring } from 'react-awesome-spinners'

export const ChangepasswordSuccess = (props) => {

    const navigate = useNavigate();

    const divAlign = {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '200px',
        justifyContent: 'space-evenly',
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/compte");
        }, 2000);
        return () => clearTimeout(timer)
    }, [])

    return (
        <div style={internalDiv.mainContainerStyle}>
            <div style={divAlign}>
                <IconOnaos style={{ margin: "-35px" }} />
                <TitleFont20WithoutMarginBlueSso
                    text="Changement de mot de passe"
                />
                <Ring />
                <UnderTextSso
                    undertext="Redirection vers la page de connexion"
                    onClick={() => navigate("/compte")}
                />
            </div>
        </div>
    )
}