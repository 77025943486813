import React from 'react'
import { NeuMorphisme, Title, DivsLine14 } from 'onaosreact'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'






export const ViewerDSIAccountDSI = (props) => {
    const accountsInfos = useSelector(state => state.products.dsi.viewer)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        width : '960px',
    }

    const getDatasAbout = (uuid) => {
        enqueueSnackbar("Les modifications concernant le compte DSI ne sont pas disponibles pour le moment. Contacter le service client si nécessaire.", { variant: 'info',autoHideDuration: 3000 })

    }

    return(
        <NeuMorphisme style = {NeuStyle}>
            <Title text = "DSI" style = {{fontSize : "22px", color : "#3C4043", paddingTop : "6px", paddingLeft : '15px'}}/>
            <div style = {{
                display : "flex",
                flexDirection:'column',
            }}>
            {accountsInfos &&
                <DivsLine14 
                    cells = {[accountsInfos.infos.name, accountsInfos.infos.firstname, accountsInfos.infos["e-mail"], "Actif"]}
                    onClick = {()=>getDatasAbout(accountsInfos.uuid)}
                    textStyle = {[{},{},{},{color : '#54A67B', fontWeight : 'bold'}]}
                    cellStyle = {{width : '20%'}}
                />
            }
            </div>
        </NeuMorphisme>
    )

}