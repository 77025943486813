import React from 'react'
import { NeuMorphisme, Title, DivsLine14,  IconButtonNoMargin } from 'onaosreact'
import { batch, useDispatch, useSelector } from 'react-redux'
import { openManage } from '../../../../redux/modals/modals'
import { getAPI, putAPI } from '../../../../api/api'
import { setAccountCreationInfos } from '../../../../redux/userdatas/UserDatas'
import { useSnackbar } from 'notistack'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { setExpertsProductInfos, setProductInfo } from '../../../../redux/products/products'




export const ViewerDSIAccountExpert = (props) => {
    const dispatch = useDispatch()
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const accountsInfos = useSelector(state => state.products.experts.viewer)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const institution = useSelector(state => state.log.institution.uuid)


    const page = useSelector(state => state.menu.actifPage)


    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        width : '960px',
    }

    const statutColor = (statut) => {
        if (statut === "Actif") return {color : '#54A67B', fontWeight : 'bold'}
        if (statut === "En attente d'activation") return {color : '#FA9D37', fontWeight : 'bold'}

    }

    const getDatasAbout = (uuid) => {
        const data = {
            uuid : uuid,
            product : page
        }
        putAPI("/expertinfos",tokenRefresh, data).then(data => {
            if (data.ok){
                batch(()=> {
                    data.infos.infos.uuid = uuid
                    dispatch(setAccountCreationInfos({infos : data.infos}))
                    dispatch(openManage())
                })
          
            }
        })
       

    }

    const onDelete = (uuid)=>{
        const datas = {
            expert : uuid,
            client : institution,
            product : page,
            forceDeco : true
        }
        putAPI("/deleteexpert", tokenRefresh, datas).then(data => {
            if (data.ok && data.code === 1000) {
                batch(()=> {
                    dispatch(setProductInfo({
                        product :page, 
                        infos : data.infosProducts
                    }))
                    dispatch(setExpertsProductInfos({
                        product :page, 
                        infos : data.infosExperts}))
                })
                enqueueSnackbar("Utilisateur supprimé avec succès", { variant: 'success' })
            } else {
                enqueueSnackbar("Une erreur s'est produite, merci de contacter le service client (code:"+ data.code+")", { variant: 'error',autoHideDuration: 3000 })
            }
        })
    }

    const doAction = (snackbarId, userUUID) => (
        <>
        <IconButtonNoMargin backgroundColor = "white">
            <CheckOutlinedIcon 
                style={{ color: 'rgb(84, 166, 123)',  width : '16px', height : '16px'}}
                onClick = {()=>{onDelete(userUUID); closeSnackbar(snackbarId)}  }
            />
        </IconButtonNoMargin> 
        <IconButtonNoMargin backgroundStyle = {{marginLeft : '10px'}} backgroundColor = "white">
            <CloseOutlinedIcon 
                style={{ color: 'rgb(196, 60, 60)',  width : '16px', height : '16px' }}
                onClick = {()=> closeSnackbar(snackbarId)}
            />
        </IconButtonNoMargin> 
        
       
        </>
      );


    return(
        <NeuMorphisme style = {NeuStyle}>
            <Title text = "Experts" style = {{fontSize : "22px", color : "#3C4043", paddingTop : "6px", paddingLeft : '15px'}}/>
            <div style = {{
                display : "flex",
                flexDirection:'column',
            }}>
            {accountsInfos && accountsInfos.map((v, k) => (
                <DivsLine14 
                    cells = {[v.infos.name, v.infos.firstname, v.infos["e-mail"], v.statut]}
                    onClick = {v.statut !== "Actif" ? ()=>getDatasAbout(v.uuid) : null}
                    key = {"accoutnInfos_"+k}
                    textStyle = {[{},{},{},statutColor(v.statut)]}
                    cellStyle = {{width : '20%'}}
                    onDelete = {v.statut === "Actif" ? 
                   ()=> enqueueSnackbar("Voulez-vous vraiment supprimer l'accès de cet utilisateur au Loinc Viewer ? ", {
                        variant:"warning",
                        action : (snackbarId) => doAction(snackbarId, v.uuid),
                        persist:true
                      })
                        : null
                    }
                />
            ))}
            </div>
        </NeuMorphisme>
    )

}