import React from "react"



export const CDAnlyseInfos = (props) => {

    return(
        <React.Fragment>
            <p>Outil permettant la vérification et la qualification des comptes rendus de biologie strucutrés en CDA R2 N3 avant leur communication au DMP et/ou prescripteur</p>
            <p>Si vous êtes intéressé n'hésitez pas à contacter notre <a href="mailto:service-client@onaos.com?subject=Demande d'infos CDAnalyse">service client</a></p>
        </React.Fragment>
    )

}