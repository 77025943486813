import React from "react"



export const VeilleInfos = (props) => {

    return(
        <React.Fragment>
            <p>Offre permettant une veille automatique des modifications opérées sur les codes LOINC lors des 2 mises à jour annuelles de la terminologie LOINC. Des alertes automatiques sont ainsi envoyées aux experts définis par le laboratoire pour contrôler les modifications apportées aux codes LOINC déjà implémentés.</p>
            <p>Ce service permet également au LBM d'obtenir un transcodage des nouveaux codes locaux mis en place après le transcodage LOINC initial, qu'il ait été réalisé par Onaos ou non.</p>
            <p>Si vous êtes intéressé n'hésitez pas à contacter notre <a href="mailto:service-client@onaos.com?subject=Demande d'infos Veille et MAJ LOINC">service client</a></p>
        </React.Fragment>
    )

}