import { configureStore } from "@reduxjs/toolkit";
import { logSlice } from "../connection/log";
import { onaosSlice } from "../onaosprojects/onaosprojects";
import { userDatasSlice } from "../userdatas/UserDatas";
import { menuSlice } from "../menu/WhichMenu";
import { productsSlice } from "../products/products";
import { modalsSlice } from "../modals/modals";
import { addExpertSlice } from "../addexpert/AddExpert";

export const store = configureStore({
    reducer:{
        log : logSlice.reducer,
        onaosProject: onaosSlice.reducer,
        userDatas: userDatasSlice.reducer,
        menu: menuSlice.reducer,
        products : productsSlice.reducer,
        modals : modalsSlice.reducer,
        addExpert : addExpertSlice.reducer,
    },
});