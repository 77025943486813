import React from 'react'
import 'onaosreact/dist/index.css';
import { InfoGeneral } from './InfoGeneral';
import { PassWord } from './PassWord';


export const InfoPrive = (props) => {


    const divStyle = {
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column wrap',
        gap : '20px',
        width : '100%',
        alignItems : 'center',

    }


    return (
        <div style={divStyle}>
            <InfoGeneral />
            <PassWord />
        </div>
    )
}