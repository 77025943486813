import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSubItem } from '../../../redux/menu/WhichMenu'
import { AdminClients } from './Clients/AdminClients'





export const ViewerAdminOnaos = (props) => {
    
    const subItem = useSelector(state => state.menu.subItem)

    const dispatch = useDispatch()

    useEffect( ()=>{
        dispatch(setSubItem({actifSubItem:"clients"}))
    }, [])


    return(
        <div style = {{display : 'flex', flexDirection:'column', gap : '24px' }}>
            {subItem === "clients" && 
                <AdminClients />
            }
        </div>
    )

}