import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSubItem } from '../../../redux/menu/WhichMenu'
import { ViewerDSIMain } from './Accounts/ViewerDSIMain'
import { ViewerDSIContratMain } from './Contracts/ViewerDSIContratMain'




export const ViewerDSI = (props) => {
    
    const subItem = useSelector(state => state.menu.subItem)
    const dispatch = useDispatch()

    useEffect( ()=>{
        dispatch(setSubItem({actifSubItem:"expert"}))
    }, [])

    return(
        <div style = {{display : 'flex', flexDirection:'column', gap : '24px' }}>
            {subItem === "expert" && 
                <ViewerDSIMain />
            }
            {subItem === "contrats" && 
                <ViewerDSIContratMain />
            }
        </div>
    )

}