import React, { useEffect } from 'react';

import {   TitleDashboard, NeuMorphismeFlex, DivsLine14, InputSearch,  } from 'onaosreact'
import { getAPI, putAPI } from '../../../../../api/api';
import { batch, useDispatch, useSelector } from 'react-redux';
import { addExpert, setExpertsAvailable, setSearch } from '../../../../../redux/addexpert/AddExpert';
import { useSnackbar } from 'notistack';



export const AddExpertsFromOnaos = (props) => {
    const institution = useSelector(state => state.log.institution)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const expertsAvailable = useSelector(state => state.addExpert.expertsAvailable)
    const searchExperts = useSelector(state => state.addExpert.clientUsersSearch)
    const expertsSelected = useSelector(state => state.addExpert.expertsSelected)
    const licenceInfos = useSelector(state => state.products.infos.viewer.licences)
    const licenceRestante = licenceInfos.nombre  - (licenceInfos.utilisées + expertsSelected.length)
    const page = useSelector(state => state.menu.actifPage)

    const accountsInfos = useSelector(state => state.products.experts.viewer)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const dispatch = useDispatch()
    
    useEffect(()=> {
    const datas = {
        product : page,
        client : institution.uuid
    }
    putAPI("/clientexperts", tokenRefresh, datas).then(data => {
        if (data.ok){
            batch (()=>{
                dispatch(setSearch(data.infos.reduce(function(acc, val){
                    const data = {
                        label : val.nom + " - " + val.prenom + " - " + val.email,
                        value : val.uuid
                    }
                    return [...acc, data]
                    }, [])))
                dispatch(setExpertsAvailable(data.infos))
            })

        }
    })
    }, [])

    const addExpertRedux = (uuid) => {
        if (licenceRestante === 0) {
            enqueueSnackbar("Licence insuffisante !", { variant: 'warning' })
            return
        } 
        const data = expertsAvailable.filter(dd => dd.uuid === uuid)
        dispatch(addExpert({newExpert : data[0], actualExperts : accountsInfos}))
    }

    const addExpertClick = (infos) => {
        if (licenceRestante === 0) {
            enqueueSnackbar("Licence insuffisante !", { variant: 'warning' })
            return

        } 
        dispatch(addExpert({newExpert : infos, actualExperts : accountsInfos}))
    }

    return (
        <div style = {{display : 'flex', flexDirection : 'column',gap : '13px' }}>
            {expertsAvailable.length >0 && <React.Fragment>
                <TitleDashboard 
                        text = "Ajouter un expert depuis un produit Onaos"
                />
                <NeuMorphismeFlex>
                    <div style = {{
                        display : "flex",
                        flexDirection:'column',
                    }}>
                        <div style = {{display : "flex", margin : "6px 16px 16px 16px"}}>
                            <InputSearch 
                                placeHolder = "Rechercher un expert dans d'autres produits Onaos"
                                style = {{height : "32px", width : "100%"}}
                                autoOptions = {searchExperts}
                                onChoice = {addExpertRedux}
                            />
                        </div>
                        <div style = {{ maxHeight : "180px", overflow : "auto" }}>
                            {expertsAvailable.map((v, k) => (
                                <DivsLine14 
                                    key = {"expertinfos_"+k}
                                    cells = {[v["nom"], v["prenom"], v["email"]]}
                                    onClick = {()=> addExpertClick(v)}
                                />
                            ))}
                        </div>
                    </div>
                </NeuMorphismeFlex>
            </React.Fragment>
            }
        </div>
    )
}