import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { putAPI } from '../../api/api'
import { Products } from '../Products/Products'
import { ViewerAdminOnaos } from './AdminOnaos/ViewerAdminOnaos'
import { ViewerDSI } from './DSI/ViewerDSI'
import { ViewerInfos } from './ViewerInfos'






export const Viewer = (props) => {

    // const access = useSelector(state => state.log.access)
    const products = useSelector(state => state.products.infos)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const page = useSelector(state => state.menu.actifPage)



    const goToOnaos = (destination) => {
        const data = {
            destination: destination,
        }
        putAPI("/redirect", tokenRefresh, data).then(data => {
            if (data.url !== undefined) {
                setTimeout(() => window.open(data.url, '_blank'), 200);
            } else {
                setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + destination, '_blank'), 200);
            }
        })
    }

    useEffect(()=> {
        if (!products[page]?.access.DSI && products[page]?.access.Expert) {
            goToOnaos(page)
        }
    }, [products])

 
    

    const loadComponent = () => {
        if (products[page]?.access.AdminOnaos) {
            return (<ViewerAdminOnaos />)
        }
        if (products[page]?.actif) {//licence valid ? 
            if (products[page]?.access.DSI ) {
                return (<ViewerDSI />)
            }
            if (!products[page]?.access.DSI && products[page]?.access.Expert) {
                return (<Products />)
            }
            return (<ViewerInfos />)
        } else {
            return (<ViewerInfos />)
        }
    }


    return(
        <React.Fragment>
            {loadComponent()}
        </React.Fragment>

    )


}