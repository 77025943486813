import React from 'react'
import { ViewerDSIAPI } from './ViewerDSIAPI'
import { ViewerDSIContract } from './ViewerDSIContract'






export const ViewerDSIContratMain = (props) => {

    return(
        <div style = {{display : 'flex', flexDirection : 'column', gap : '24px'}}>
            <ViewerDSIContract />
            <ViewerDSIAPI />
        </div>
    )

}