import React from 'react';

import {ButtonAjouter, } from 'onaosreact'
import { batch, useDispatch, useSelector } from 'react-redux';
import { resetExpert } from '../../../../../redux/addexpert/AddExpert';
import { closeAddExpert } from '../../../../../redux/modals/modals';
import { useSnackbar } from 'notistack';
import { putAPI } from '../../../../../api/api';
import { setDSIProductInfos, setExpertsProductInfos, setProductInfo } from '../../../../../redux/products/products';



export const AddExpertsBtns = (props) => {

    const experts = useSelector(state => state.addExpert.expertsSelected)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const institution = useSelector(state => state.log.institution.uuid)
    const page = useSelector(state => state.menu.actifPage)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()


 

    const sendExpert = () => {
        if (experts.length > 0) {
            const datas = {
                experts : experts,
                client : institution,
                product : page
            }
            putAPI("/addexperts", tokenRefresh, datas).then(data => {
                if (data.ok && data.code === 1000) {
                    const msg = experts.length > 1 ? "Experts ajoutés avec succès" : "Expert ajouté avec succès"
                    enqueueSnackbar(msg, { variant: 'success' })
                    batch( ()=> {
                        dispatch(setProductInfo({
                            product : page, 
                            infos : data.infosProducts
                        }))
                        dispatch(setExpertsProductInfos({
                            product : page, 
                            infos : data.infosExperts}))
                        dispatch(resetExpert())
                        dispatch(closeAddExpert()) 
                    })

                } else {
                    enqueueSnackbar("Une erreur s'est produite, merci de contacter le service client (code:"+ data.code+" - "+data.msg+")", { variant: 'error',autoHideDuration: 3000 })
                    batch(() => {
                        dispatch(resetExpert())
                        dispatch(closeAddExpert()) 
                    })
                }
            })
        }
   
        

    }




    return (

            <div style = {{display : 'flex',justifyContent:'flex-end'}}>
                <ButtonAjouter 
                    onClick = {sendExpert}
                    style = {experts.length === 0 && {backgroundColor : "rgb(187, 184, 184)"}}
                />
            </div>


       
    )
}