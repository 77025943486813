import React from 'react'

import { ViewerDSILicence } from './ViewerDSILicence'
import { ViewerDSIAccountExpert } from './ViewerDSIAccountExpert'
import { useSelector } from 'react-redux'
import { ViewerManageExpert } from './ViewerManageExpert'
import { AddExpertsMain } from './AddExpert/AddExpertsMain'
import { ViewerDSIAccountDSI } from './ViewerDSIAccountDSI'




export const ViewerDSIMain = (props) => {
    
    const showManage = useSelector(state => state.modals.openManage)
    const showAdd = useSelector(state => state.modals.openAdd)
    const accountsInfos = useSelector(state => state.products.experts.viewer)


    return(
        <div style = {{display : 'flex', flexDirection:'column', gap : '24px' }}>
            {showManage && <ViewerManageExpert />}
            {showAdd && <AddExpertsMain />}

            <ViewerDSILicence />

            <ViewerDSIAccountDSI />

            {/*//TODO only if at least one account */}

            {accountsInfos && accountsInfos.length>0 && <ViewerDSIAccountExpert />}
            

        </div>
    )

}