import { NavBar } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putAPI } from '../api/api'
import { unLog } from '../redux/connection/log'




export const MenuViewer = (props) => {

    const email = useSelector(state => state.log.email)
    const user = useSelector(state => state.log.name)

    const oanosProject = useSelector(state => state.log.oanosProject)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch()

    const goto = (id, destination) => {
        const data = {
            destination: destination,
        }
        putAPI("/redirect", tokenRefresh, data).then(data => {
            if (data.url !== undefined) {
                setTimeout(() => window.open(data.url, '_blank'), 200);
            } else {
                setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + destination, '_blank'), 200);
            }
        })
    }

    const openExit = () => {
        dispatch(unLog())
    }

    const getOnaosProject = () => {
        let data = []
        oanosProject.map(v =>
            data.push({ label: v.projet.charAt(0).toUpperCase() + v.projet.slice(1), click: goto, params: v.projet })
        )
        return data
    }

    const subMenuAccount = [
        { label: "Mon compte", },
    ]

    const MenuData = [
        { label: "Déconnexion", click: openExit },
    ]

    const openOnaosWebSite = () => {
        setTimeout(() => window.open('https://www.onaos.com', '_blank'), 200);
    }

    return (
        <NavBar
            projectName=""
            menuDatas={MenuData}
            menuOnaosDatas={getOnaosProject()}
            menuAccountDatas={subMenuAccount}
            onaosClick={openOnaosWebSite}
            user={user}
            email={email}
            menuLabel = "Menu Compte"
        />
    )
}