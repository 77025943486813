import React from 'react'
import 'onaosreact/dist/index.css';
import { TitleFont20, NeuMorphisme, IconButton } from 'onaosreact'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export const PassWord = (props) => {

    const infos = useSelector( state => state.userDatas)
    const userInfos = infos.userInfos
    const navigate = useNavigate()



    const NeuStyle = {
        padding: "10px 0 0 0",
        height: '140px',
        width: '720px',
        display: 'flex',
        flexDirection: 'column',
    }

    const LineStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    }

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column wrap',
    }


    return (
        <div style={divStyle}>
            <NeuMorphisme style={NeuStyle}>
                <TitleFont20
                    text={"Mot de passe"}
                />
                <div style={{ padding: " 0 20px" }}>
                    <div style={LineStyle}>
                        <div style={{ fontSize: "18px" }} >••••••••••••••</div>
                        <IconButton> 
                            <CreateOutlinedIcon 
                                onClick={ () => navigate("/change")}  
                                style = {{color : '#3C4043'}} 
                            />
                        </IconButton>
                    </div>
                    <p>Dernière modification: {userInfos.lastchange}</p>
                </div>
            </NeuMorphisme>
        </div>
    )
}