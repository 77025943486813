import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetTempAccess, setDash, setTempAccess } from '../redux/connection/log.js'
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

import { MenuDashboard } from './Menus/MenuDashboard.js';
import { Products } from './Products/Products.js';
import { InfoPrive } from './Account/MainInfoPrive.js';
import { MenuSwitch } from './Menus/MenuSwitch.js';
import { setPage } from '../redux/menu/WhichMenu.js';
import { SimpleSelect } from 'onaosreact';
import { getAPI } from '../api/api.js';
import { Viewer } from './Viewer/Viewer.js';


export const Dashboard = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {


        if (searchParams.has('t') || searchParams.has('sso')) {
            let newParams = []
            setSearchParams(newParams);
            navigate({
                search: createSearchParams(newParams).toString(),
            }, { replace: true });
        }
        dispatch(setDash(false))

    })
    

    useEffect(() => {
        dispatch(setPage({actifPage:props.actifPage}))
    })

    const getActivesPages = () => {
        if (props.actifPage ===  "products") {
            return (<Products />)
        }
        if (props.actifPage ===  "account") {
            return (<InfoPrive />)
        }

        // if (props.actifPage ===  "viewer") {
        //     return (<Viewer />)
        // }

        return (<Viewer />)
    }


    return (
        <div style={{ display: "flex", width : '100%' ,justifyContent : 'space-between'}}>
            <div style={{ display: "flex", flexDirection: "column", gap: "25px", minWidth : '320px' }}>
                <MenuDashboard />
                <MenuSwitch />
            </div>
            <div style = {{ marginLeft : 'auto',marginRight : 'auto' }}>
                {getActivesPages()}
            </div>
            <div style={{ display: "flex", height:'50px', minWidth : '320px' }}>
                
            </div>
        </div>
    )
}