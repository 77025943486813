import React from 'react';
import { IconMapper, IconUpdate, IconViewer, IconAnnotateur, } from 'onaosreact'
import { useDispatch, useSelector, batch } from "react-redux";
import { putAPI } from '../../api/api';
import { useNavigate } from "react-router";
import { setPage } from '../../redux/menu/WhichMenu';
import { setExpertsProductInfos, setProductInfo, setDSIProductInfos } from '../../redux/products/products';
import { setAccess } from '../../redux/connection/log';


export const Products = (props) => {

    const infos = useSelector( state => state.menu)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const institution = useSelector(state => state.log.institution)
    const page = useSelector(state => state.menu.actifPage)

    const SquarePos = {
        display: 'flex',
        flexFlow: "row wrap",
        marginTop: '25px',
        width : '960px',
        justifyContent : 'space-between'
    }

    const goTo = (label) => {
        const link = infos.menu[label] === undefined ? "/produits" : infos.menu[label].link
        const labellink = infos.menu[label] === undefined ? "products" : label
        if (labellink !== 'products') {
            const datas = {
                product : labellink,
                client : institution.uuid
            }
            putAPI("/productinfos", tokenRefresh, datas).then(data => {
                if (data.ok){
                    batch( ()=> {
                        dispatch(setProductInfo({
                            product :labellink, 
                            infos : data.infosProducts
                        }))
                        dispatch(setExpertsProductInfos({
                            product : labellink, 
                            infos : data.infosExperts}))
                        dispatch(setDSIProductInfos({
                            product : labellink, 
                            infos : data.infosDSI}))
                        dispatch(setPage({actifPage :labellink}))
                        navigate(link);  
                    })
            
                } else {
                    batch( ()=> {
                    dispatch(setPage({actifPage :labellink}))
                    navigate(link); 
                    })
                }
            })
        
        }
 
    }

    return (
        <div style={SquarePos}>
            <IconMapper text={"LOINC Mapper"} onClick = { ()=> goTo("mapper") } active = {page === "mapper"}/>
            <IconUpdate text={"Veille et mise à jour"} onClick = { ()=> goTo("update") } active = {page === "update"}/>
            <IconViewer text={"CDAnalyse"} onClick = { ()=> goTo("viewer") } active = {page === "viewer"}/>
            <IconAnnotateur text={"Annotateur"} onClick = { ()=> goTo("annotator") } active = {page === "annotator"}/>
        </div>
    )


}

