import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,

} from "react-router-dom";

import { useSelector } from 'react-redux';

import './App.css';
import { Dashboard } from './dashboard/Dashboard';
import { putAPI } from './api/api';
import { setToken } from './redux/connection/log';

import { closeState } from './redux/onaosprojects/onaosprojects';
import { ChangePassword } from './dashboard/Account/ChangePassword';

import { setData } from './redux/userdatas/UserDatas';
import { MenuViewer } from './menu/MenuDashBoard';
import { ChangepasswordSuccess } from './dashboard/Account/ChangepasswordSucces';
import { ForgetPassword } from './dashboard/Account/ForgetPassword';
import { InfoPrive } from './dashboard/Account/MainInfoPrive';


export const App = () => {

  const log = useSelector((state) => state.log)
  const dispatch = useDispatch()
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let t = params.get('t');

  let sso = params.get('sso');

  const closeOnaos = () => {
    dispatch(closeState())
  }


  useEffect(() => {

    if (t !== null && t !== "" && t !== undefined && !log.connect) {

      const data = {
        token: t,
      }

      putAPI("/checkredirect", "", data).then(data => {
        if (data.token !== undefined && data.token !== "") {
          dispatch(setToken(data))
          dispatch(setData({
            userDatas:
              { ...data.userdatas.infos, ...data.passinfo }
          }))
        } else {
          setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + process.env.REACT_APP_PROJET_NAME, '_self'), 200);
        }
      })
    }

    if (t === null && !log.connect && (sso === undefined || sso === null)) {
      setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + process.env.REACT_APP_PROJET_NAME, '_self'), 200);
    }


    if (sso !== undefined && sso !== null && !log.connect && t === null) {

      const data = {
        token: sso,
        service: process.env.REACT_APP_PROJET_NAME
      }

      putAPI("/checktoken", "", data).then(data => {
        if (data.token !== undefined && data.token !== null && data.token !== "") {
          dispatch(setToken(data))
          dispatch(setData({
            userDatas:
              { ...data.userdatas.infos, ...data.passinfo }
          }))
        } else {
          setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + "/?r=" + process.env.REACT_APP_PROJET_NAME, '_self'), 200);
        }

      })
    }
  }, [params])

  return (
    <React.Fragment>
         {log.connect && <MenuViewer />}
        <Router>
          <Routes>

            {log.connect && <Route path="/produits" element={<Dashboard actifPage = "products"/>} />}
            {log.connect && <Route path="/compte" element={<Dashboard actifPage = "account"/>} />}
            {log.connect && <Route path="/viewer" element={<Dashboard actifPage = "viewer"/>} />}
            {log.connect && <Route path="/annotateur" element={<Dashboard actifPage = "annotateur"/>} />}
            {log.connect && <Route path="/veilleetmaj" element={<Dashboard actifPage = "veilleetmaj"/>} />}
            {log.connect && <Route path="/loinc-mapper" element={<Dashboard actifPage = "loinc-mapper"/>} />}


            {log.dashboard && log.connect &&
              <Route
                path="*"
                element={<Navigate replace to="/produits" />}
              />
            }

            {log.connect && <Route exact path="/change" element={<ChangePassword />} />}
            {log.connect && <Route exact path="/oubli" element={<ForgetPassword />} />}
            {log.connect && <Route exact path="/changepasswordsuccess" element={<ChangepasswordSuccess />} />}

           
          

          

          </Routes>
        </Router>
    </React.Fragment>
  );
}


