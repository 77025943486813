import React from 'react'
import { NeuMorphisme, Title, DivsLine1114, LineTextWithLink } from 'onaosreact'
import { useDispatch, useSelector } from 'react-redux'
import { openAddExpert } from '../../../../redux/modals/modals'
import { useSnackbar } from 'notistack'






export const ViewerDSILicence = (props) => {
    const dispatch = useDispatch()

    const licenceInfos = useSelector(state => state.products.infos.viewer.licences)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const NeuStyle = {
        display: 'flex',
        flexDirection: 'column',
        width : '960px',
    }

    const openAddExperts = () => {
        //GET to find all expert account for this client minus account for this specific product
        // need 2 results : one for input search options, one for table data
        if  (licenceInfos.utilisées < licenceInfos.nombre){
            dispatch(openAddExpert())
        } else {
            enqueueSnackbar("Licence restante insuffisante !", { variant: 'error' })
        }
        
    }

    return(
        <NeuMorphisme style = {NeuStyle}>
            <Title text = "Gestions des comptes" style = {{fontSize : "22px", color : "#3C4043", paddingTop : "6px", paddingLeft : '15px'}}/>
            <div style = {{
                display : "flex",
                flexDirection:'column',
            }}>
                <DivsLine1114 
                    cells = {[
                        "LICENCES UTILISÉES*", licenceInfos.utilisées+"/"+licenceInfos.nombre
                    ]} 
                />
                <DivsLine1114 
                    cells = {[
                        "GESTION DES EXPERTS", "Ajouter un compte"
                    ]} 
                    onClick = {()=>openAddExperts()}
                />
                <LineTextWithLink 
                    text = "*Pour toutes demandes de modifications concernant le nombre de licence, merci de contacter notre "
                    link = "service client"
                    onClick = {() => console.log("click")}
                />
            </div>
            
        </NeuMorphisme>

    )

}