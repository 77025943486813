import React from "react"



export const MapperInfos = (props) => {

    return(
        <React.Fragment>
            <p>Plateforme de transcodage LOINC permettant à partir de codes locaux (codes de chapitres, de demandes ou de résultats) d'associer le code LOINC correspondant.</p>
            <p>Elle inclue également un moteur de recherche qui permet de requêter la terminologie LOINC et tous ses jeux de valeurs (international, FR complet et circuit de la biologie)</p>
            <p>Si vous êtes client, l'accès se fait via cette adresse : <a href = "https://loinc-mapper.onaos.com">Loinc Mapper</a>, dans le cas contraire n'hésitez pas à contacter notre <a href="mailto:service-client@onaos.com?subject=Demande d'infos Loinc Mapper">service client</a></p>

        </React.Fragment>
    )

}